import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"d-flex align-center justify-start"},[_c('swDateRangePicker',{attrs:{"data-test":("" + _vm.dates),"start-date":_vm.dates[0],"end-date":_vm.dates[1],"min-date":((_vm.$store.getters.years[0]) + "-01-01")},on:{"updateDateRange":function($event){return _vm.updateDates($event)}}}),_c(VTextField,{staticClass:"ml-4",staticStyle:{"max-width":"80px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.$store.state.articleStatus.itemsPerPage,"label":_vm.swT('rowsperpage'),"type":"number","min":"1","max":"1000","data-cy":"rowsPerPage","data-test":("rowsPerPage-" + (_vm.$store.state.articleStatus.itemsPerPage))},on:{"input":function($event){_vm.$store.state.articleStatus.itemsPerPage = Math.max(1, parseInt($event, 10) || 1)}}}),(_vm.totalItems)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-4 primary--text",staticStyle:{"max-width":"80px"},attrs:{"hide-details":"","dense":"","disabled":"","label":_vm.swT('total_items')}},'div',_vm.$attrs,false),on),[_vm._v(" "+_vm._s(_vm.totalItems)+" ")])]}}],null,false,3813551223)},[_c('span',[_vm._v(_vm._s(_vm.swT('total_items')))])]):_vm._e()],1),(_vm.$store.state.reportViz === 'table')?_c(VCol,{staticClass:"d-flex align-center justify-center"},[(_vm.showSubtotalsButton)?_c(VBtn,{staticClass:"mx-1",attrs:{"small":"","hide-details":"","label":_vm.swT('withSubtotals'),"data-cy":"subtotals","data-test":("subtotals" + (_vm.$store.state.articleStatus.withSubtotals))},on:{"click":function($event){$event.stopPropagation();_vm.$store.state.articleStatus.showSubtotals = !_vm.$store.state.articleStatus.showSubtotals}}},[_vm._v(" "+_vm._s(_vm.subtotalsButtonText)+" ")]):_vm._e(),_c(VBtn,{staticClass:"mx-1",attrs:{"small":"","hide-details":"","label":_vm.swT('articleStatusTableViz'),"data-cy":("switchArticleStatusViz-" + (_vm.$store.state.articleStatusTableViz)),"data-test":"switchArticleStatusViz"},on:{"click":function($event){return _vm.switchArticleStatusViz()}}},[_vm._v(" "+_vm._s(_vm.swT(_vm.$store.state.articleStatusTableViz === 'chart' ? 'table' : 'chart'))+" ")])],1):_vm._e(),_c(VCol,{staticClass:"d-flex align-center justify-end pr-3"},[(_vm.localConsistencyOK)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"info","large":""}},[_vm._v("mdi-check-decagram")])],1)]}}],null,false,4143657344)},[_c('span',[_vm._v(_vm._s(_vm.swT('consistency_checked'))+" "+_vm._s(_vm.localConsistencyChecked))])]):_vm._e(),(_vm.localConsistencyWaiting)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"warning","large":""}},[_vm._v("mdi-alert-decagram")])],1)]}}],null,false,80396012)},[_c('span',[_vm._v(_vm._s(_vm.swT('waiting_for_transaction_data_to_process')))])]):_vm._e(),_c(VBtn,{staticClass:"mx-1",attrs:{"small":"","hide-details":"","color":"info"},on:{"click":function($event){$event.stopPropagation();_vm.$store.state.foldReportsUI = !_vm.$store.state.foldReportsUI}}},[(!_vm.$store.state.foldReportsUI)?_c(VIcon,[_vm._v("mdi-fullscreen")]):_c(VIcon,[_vm._v("mdi-fullscreen-exit")])],1),(_vm.$store.state.reportViz !== 'table')?_c('div',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","block":"","color":"success","data-test":"printButton"},on:{"click":_vm.print}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-printer")])],1)]}}],null,false,4294174510)},[_c('span',[_vm._v(_vm._s(_vm.swT('print')))])])],1):_vm._e(),(_vm.$store.state.reportViz === 'table')?_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"no-print",attrs:{"color":"success","data-test":"actionMenu","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu")])],1)]}}],null,false,314742343)},[_c('div',{staticClass:"pt-1 pb-1 px-1 no-print",staticStyle:{"background-color":"white"},attrs:{"color":"white"}},[_c(VRow,{staticClass:"pa-0 ma-0"},[_c(VCol,{staticClass:"pa-0 pr-1"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","block":"","color":"success","data-test":"printButton"},on:{"click":_vm.print}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-printer")])],1)]}}],null,false,4294174510)},[_c('span',{staticClass:"no-print"},[_vm._v(_vm._s(_vm.swT('print')))])])],1),_c(VCol,{staticClass:"pa-0 pl-1"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.reportViz == 'table'),expression:"$store.state.reportViz == 'table'"}],attrs:{"small":"","block":"","color":"success","loading":_vm.$store.state.exportBusy,"data-test":"copyToExcelButton"},on:{"click":_vm.copyToExcel}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-microsoft-excel")])],1)]}}],null,false,2782339994)},[_c('span',[_vm._v(_vm._s(_vm.swT('export_report_to_excel')))])])],1)],1)],1),(_vm.$store.state.reportViz === 'table')?_c('div',{staticClass:"no-print"},_vm._l((_vm.commands),function(command){return _c('div',{key:command.command,staticClass:"pb-1 px-1",staticStyle:{"background-color":"white"}},[_c(VBtn,{attrs:{"small":"","block":"","color":command.color,"data-test":("command-" + (command.command))},on:{"click":function($event){return _vm.callRunCommand(command)}}},[(command.icon)?_c(VIcon,[_vm._v(" "+_vm._s(command.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.swT(command.command))+" ")],1)],1)}),0):_vm._e(),_c('div',{staticClass:"pb-1 px-1 no-print",staticStyle:{"background-color":"white"},attrs:{"color":"white"}},[_c(VRow,{staticClass:"pa-0 ma-0"},[_c(VCol,{staticClass:"pa-0 pr-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.state.reportViz === 'table')?_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","block":"","hide-details":"","label":_vm.swT('fullUI'),"color":"info","data-test":"goToWizard"},on:{"click":function($event){$event.stopPropagation();_vm.$store.state.reportsFilterFullUI = !_vm.$store.state.reportsFilterFullUI
                    _vm.localFilterStepperModel = 0}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-wizard-hat")])],1):_vm._e()]}}],null,false,555442847)},[_c('span',[_vm._v(_vm._s(_vm.swT('go_to_wizard')))])])],1),_c(VCol,{staticClass:"pa-0 pl-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.reportViz == 'table'),expression:"$store.state.reportViz == 'table'"}],attrs:{"small":"","block":"","color":"info","data-test":"copyDeepLink"},on:{"click":function($event){return _vm.copyDeeplinkHandler()}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-link-variant")])],1)]}}],null,false,374510990)},[_c('span',[_vm._v(_vm._s(_vm.swT('copy_report_link')))])])],1)],1)],1)]):_vm._e(),(_vm.$store.state.reportViz == 'stockinsight')?_c('div',{staticClass:"ml-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","block":"","color":"info","data-test":"copyDeepLink"},on:{"click":function($event){return _vm.copyDeeplinkHandler()}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-link-variant")])],1)]}}],null,false,2816112247)},[_c('span',[_vm._v(_vm._s(_vm.swT('copy_report_link')))])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }