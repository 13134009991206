import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-4",attrs:{"align":"center"}},[(_vm.dataTableRows.length)?_c(VRow,_vm._l((_vm.dataTableRows),function(row,key){return _c(VCol,{key:key,attrs:{"cols":"6","sm":"6","md":"4","lg":"3","xl":"2"}},[_c(VCard,{attrs:{"elevation":"6"}},[_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":_vm.image(row, 600),"lazy-src":_vm.image(row, 75),"aspect-ratio":"1"}}),_vm._l((row.keywords),function(keyword,index){return _c('div',{key:index,staticClass:"caption"},[_vm._v(_vm._s(keyword))])}),_c(VDivider),_c(VList,{attrs:{"dense":""}},_vm._l((row.kpis),function(kpi,index){return _c(VListItem,{key:index,staticClass:"px-2"},[_c(VListItemContent,[_vm._v(_vm._s(kpi.label))]),_c('b',[_vm._v(_vm._s(_vm.formatCell(kpi.fieldIndex, kpi.value)))])],1)}),1)],2)],1)}),1):_c(VRow,{staticClass:"d-flex justify-center"},[_c(VCol,[_c('div',[_vm._v(_vm._s(_vm.swT('noData')))])])],1)],1),_c('swScrollTopFab')],1)}
var staticRenderFns = []

export { render, staticRenderFns }