import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"},{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],ref:"fab",staticClass:"z",attrs:{"data-test":"scroll-top","fab":"","dark":"","fixed":"","bottom":"","right":"","color":hover ? 'info' : '#2196f32e'},on:{"click":_vm.toTop}},[_c(VIcon,{attrs:{"name":"test"}},[_vm._v("mdi-arrow-up")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }