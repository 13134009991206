<template>
  <v-simple-table>
    <thead>
      <tr>
        <th class="primary" style="height:10px">
          <span>
            KPI
          </span>
        </th>
        <th v-for="sizeObj in sortedSizes" :key="sizeObj.size" class="primary text-right" style="height:10px">
          <span>
            {{ sizeObj.size }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(kpiObj, index) in visibleKpis" :key="kpiObj.value" :class="index % 2 ? 'blue-grey lighten-5' : ''">
        <td style="height:12px">{{ swT(kpiObj.text) }}</td>
        <td v-for="sizeObj in sortedSizes" :key="sizeObj.size" style="height:12px" class="text-right">
          {{ formatKpi(kpiObj.fieldType, kpis[sizeObj.size][kpiObj.value]) }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import products from '@/functions/products'
import { format } from 'date-fns'
import { swT } from '@/functions/i18n'

export default {
  name: 'StockInsightSizeTable',
  props: {
    kpis: Object,
  },
  data() {
    return {
      swT,
    }
  },
  computed: {
    sortedSizes() {
      const sizes = Object.keys(this.kpis).map((size) => ({ size }))
      sizes.forEach((size) => products.buildSizeIndex(this.$store.getters.sizeOrdering, this.$store.getters.mapSubSizes)(size))
      return sizes.length > 1 ? sizes.sort((a, b) => `${a.sizeIndex}${a.size}`.localeCompare(`${b.sizeIndex}${b.size}`)) : sizes
    },
    visibleKpis() {
      return this.$store.state.articleStatus.selectedFields
    },
  },
  methods: {
    formatKpi(fieldType, value) {
      if (fieldType == 'date') return format(value, 'yyyy-MM-dd')
      if (fieldType == 'percentage') return value === Infinity || value === -Infinity || isNaN(value) ? '-' : value.toFixed(2) + '%'
      if (fieldType == 'currency') return '€ ' + value.toFixed(2)
      return value.toFixed(fieldType)
    },
  },
}
</script>
