<template>
  <!-- ScrollTop FAB -->
  <v-hover v-slot:default="{ hover }">
    <v-btn v-show="fab" ref="fab" v-scroll="onScroll" class="z" data-test="scroll-top" fab dark fixed bottom right :color="hover ? 'info' : '#2196f32e'" @click="toTop">
      <v-icon name="test">mdi-arrow-up</v-icon>
    </v-btn>
  </v-hover>
</template>
<script>
export default {
  name: 'ScrollTopFab',
  data() {
    return {
      fab: false,
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>

<style scoped>
.z {
  z-index: 1000;
}
</style>
