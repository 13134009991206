<template>
  <div>
    <div align="center" class="mt-4">
      <v-row v-if="dataTableRows.length">
        <v-col v-for="(row, key) of dataTableRows" :key="key" cols="6" sm="6" md="4" lg="3" xl="2">
          <v-card elevation="6">
            <v-img :src="image(row, 600)" :lazy-src="image(row, 75)" aspect-ratio="1" class="grey lighten-2" />
            <div v-for="(keyword, index) of row.keywords" :key="index" class="caption">{{ keyword }}</div>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item v-for="(kpi, index) of row.kpis" :key="index" class="px-2">
                <v-list-item-content>{{ kpi.label }}</v-list-item-content>
                <b>{{ formatCell(kpi.fieldIndex, kpi.value) }}</b>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="d-flex justify-center">
        <v-col>
          <div>{{ swT('noData') }}</div>
        </v-col>
      </v-row>
    </div>
    <swScrollTopFab />
  </div>
</template>

<script>
import { format } from 'date-fns'
import { articleStatus } from '@softwear/latestcollectioncore'
import reportVisualizationFunctions from '../functions/reportVisualizationFunctions'
import swScrollTopFab from '../components/swScrollTopFab.vue'
import globalStore from '../store/globalStore'
import { eventBus } from '../main'
import { swT } from '@/functions/i18n'
import tools from '../functions/tools'

export default {
  components: { swScrollTopFab },
  props: ['aggregations', 'selectedFields', 'fieldChoices', 'dates', 'selectedGroups', 'itemsPerPage'],
  data() {
    return {
      swT,
      fields: {},
    }
  },
  computed: {
    fieldTypes() {
      const result = []
      result[0] = 0
      this.fieldChoices.forEach((e) => (result[e.value] = e.fieldType))
      return result
    },
    dataTableRows() {
      if (!this.selectedFields.length) return []
      const dateRange = articleStatus.getDateRangeFromPicker(this.dates)
      const timeFrame = dateRange.endTimeStamp - dateRange.beginTimeStamp
      if (!this.aggregations) return []
      const lowerCaseFilter = this.$store.state.filter?.toLowerCase() || ''
      return Object.entries(this.aggregations)
        .filter((oneAggregatedRow) => oneAggregatedRow[0] != '** TOTAL **')
        .filter((oneAggregatedRow) => !lowerCaseFilter || oneAggregatedRow[0].toLowerCase().includes(lowerCaseFilter))
        .map((oneAggregatedRow) => {
          // Convert the raw vector to a format for the v-data-table
          const key = oneAggregatedRow[0]
          const vector = [].slice.call(oneAggregatedRow[1]) // vector is now a normal Array instead of a Float64Array so can be extended with additional fields
          articleStatus.postAgg(vector, timeFrame)
          const returnValue = {
            keywords: key.split('\t'),
            key: '',
            kpis: this.selectedFields.map((selectedVectorIndex) => ({
              label: this.fields['' + selectedVectorIndex],
              value: vector[selectedVectorIndex],
              fieldIndex: selectedVectorIndex,
            })),
          }
          return returnValue
        })
        .filter((oneAggregatedRow) => oneAggregatedRow.kpis?.[0]?.value != 0)
        .sort((a, b) => b.kpis?.[0]?.value - a.kpis?.[0]?.value)
        .slice(0, this.itemsPerPage)
    },
  },
  created() {
    eventBus.$on('updateVisualizations', () => {
      this._computedWatchers.dataTableRows.run()
      this.$forceUpdate()
    })
    this.fields = this.fieldChoices.reduce((agg, item) => {
      agg[item.value] = item.text
      return agg
    }, {})
  },
  beforeDestroy() {
    eventBus.$off('updateVisualizations')
  },
  methods: {
    image(row, width) {
      const productKey = `${row.keywords[2]}__${row.keywords[0]}`
      const index = globalStore.getIndexedProducts()
      const images = index[productKey]?.[0]?.images
      if (!images) return ''
      return this.imageSrc(images.split(',')[0], width)
    },
    formatCell(fieldIndex, value) {
      const fieldType = this.fieldTypes[fieldIndex]
      if (fieldType == 'date') return format(value, 'yyyy-MM-dd')
      if (fieldType == 'percentage') return value === Infinity || value === -Infinity || isNaN(value) ? '-' : value.toFixed(2) + '%'
      if (fieldType == 'currency') return '€ ' + value.toFixed(2)
      return value.toFixed(fieldType)
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
  },
}
</script>
