<template>
  <v-container class="pt-0">
    <swArticleStatusCardsVisualization
      v-if="$store.state.reportViz == 'cards'"
      :aggregations="aggregations"
      :selected-fields="computedSelectedColumns"
      :field-choices="$store.getters.translatedReportColumns"
      :selected-groups="computedSelectedGroups"
      :dates="dates"
      :items-per-page="computedItemsPerPage"
    />
    <swArticleStatusMatrixVisualization
      v-if="$store.state.reportViz == 'matrix'"
      class="mx-n1"
      :aggregations="aggregations"
      :selected-fields="computedSelectedColumns"
      :field-choices="$store.getters.translatedReportColumns"
      :selected-groups="computedSelectedGroups"
      :dates="dates"
      :items-per-page="computedItemsPerPage"
      :hide-zeroes="hideZeroes"
      :show-image="showImage"
      :show-negative-values="showNegativeValues"
      :show-positive-values="showPositiveValues"
    />

    <div v-if="$store.state.reportViz == 'table' || $store.state.reportViz == 'customertable'">
      <swArticleStatusTableVisualization
        v-show="$store.state.articleStatusTableViz === 'table' || $store.state.articleStatusTableViz === 'customertable'"
        :aggregations="aggregations"
        :selected-fields="computedSelectedColumns"
        :field-choices="$store.getters.reportColumnsAbbreviated"
        :table-sort-by="tableSortBy"
        :table-sort-desc="tableSortDesc"
        :selected-groups="computedSelectedGroups"
        :dates="dates"
        :items-per-page="computedItemsPerPage"
        :with-subtotals="withSubtotals"
        :group-choices="groupChoices"
        @setManualFilter="$emit('setManualFilter', $event)"
        @changeQuery="$emit('changeQuery', $event)"
        @update:sort-by="$emit('update:sort-by', $event)"
        @update:sort-desc="$emit('update:sort-desc', $event)"
      />
      <swArticleStatusChartVisualization
        v-show="$store.state.articleStatusTableViz === 'chart'"
        :aggregations="aggregations"
        :selected-fields="computedSelectedColumns"
        :field-choices="$store.getters.translatedReportColumns"
        :table-sort-by="tableSortBy"
        :table-sort-desc="tableSortDesc"
        :selected-groups="computedSelectedGroups"
        :dates="dates"
        :items-per-page="computedItemsPerPage"
      />
    </div>
    <swArticleStatusStockInsightVisualization
      v-if="$store.state.reportViz == 'stockinsight'"
      :selected-fields="computedSelectedColumns"
      :dates="dates"
      :items-per-page="computedItemsPerPage"
    />
  </v-container>
</template>

<script>
import { swT } from '@/functions/i18n'
import swArticleStatusTableVisualization from './swArticleStatusTableVisualization.vue'
import swArticleStatusChartVisualization from './swArticleStatusChartVisualization.vue'
import swArticleStatusCardsVisualization from './swArticleStatusCardsVisualization.vue'
import swArticleStatusMatrixVisualization from './swArticleStatusMatrixVisualization.vue'
import swArticleStatusStockInsightVisualization from './swArticleStatusStockInsightVisualization.vue'

export default {
  components: {
    swArticleStatusTableVisualization,
    swArticleStatusChartVisualization,
    swArticleStatusCardsVisualization,
    swArticleStatusMatrixVisualization,
    swArticleStatusStockInsightVisualization,
  },
  props: [
    'aggregations',
    'dates',
    'groupChoices',
    'hideZeroes',
    'itemsPerPage',
    'selectedGroups',
    'selectedTab',
    'showImage',
    'showNegativeValues',
    'showPositiveValues',
    'tableSortBy',
    'tableSortDesc',
    'withSubtotals',
  ],
  data() {
    return {
      swT,
    }
  },
  computed: {
    computedItemsPerPage() {
      return this.$store.state.articleStatus.itemsPerPage || 10
    },
    computedSelectedGroups() {
      if (!this.$store.state.articleStatus.selectedGroups) return []
      return this.$store.state.articleStatus.selectedGroups.map((group) => group.value)
    },
    computedSelectedColumns() {
      if (!this.$store.state.articleStatus.selectedFields) return []
      return this.$store.state.articleStatus.selectedFields.map((field) => field.value)
    },
  },
}
</script>
