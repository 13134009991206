<template>
  <div>
    <div v-if="selectedGroups.length == 1">
      <apexchart type="bar" :height="availableHeight" width="100%" :options="chartOptions" :series="series"></apexchart>
    </div>
    <div v-else align="center">
      <br />
      <h2>Select exactly one grouping to see chart.</h2>
    </div>
  </div>
</template>

<script>
import { articleStatus } from '@softwear/latestcollectioncore'
import reportVisualizationFunctions from '../functions/reportVisualizationFunctions'
import { eventBus } from '../main'

export default {
  props: ['aggregations', 'selectedFields', 'fieldChoices', 'dates', 'selectedGroups', 'itemsPerPage', 'tableSortBy', 'tableSortDesc'],
  data() {
    return {
      windowHeight: 0,
    }
  },
  computed: {
    availableHeight() {
      if (this.$vuetify.breakpoint.xs) return this.windowHeight - 200 + ''
      return this.windowHeight - 520 + ''
    },
    dataTableRows() {
      const dateRange = articleStatus.getDateRangeFromPicker(this.dates)
      const timeFrame = dateRange.endTimeStamp - dateRange.beginTimeStamp
      let rowId = 0
      if (!this.aggregations) return []
      return (
        Object.entries(this.aggregations)
          // .filter((oneAggregatedRow) => oneAggregatedRow[0] != '** TOTAL **')
          .map((oneAggregatedRow) => {
            // Convert the raw vector to a format for the v-data-table
            const returnValue = { id: rowId++ }
            const key = oneAggregatedRow[0]
            const vector = [].slice.call(oneAggregatedRow[1]) // vector is now a normal Array instead of a Float64Array so can be extended with additional fields
            articleStatus.postAgg(vector, timeFrame)

            key.split('\t').forEach((item, index) => {
              returnValue['g' + index] = item == 'undefined' ? '' : item
            })
            vector.forEach((item, index) => {
              if (this.selectedFields.includes(index)) {
                returnValue['i' + index] = parseInt(item)
                returnValue['v' + index] = item
              }
            })
            return returnValue
          })
      )
    },
    chartableRows() {
      return this.dataTableRows
        .filter((row) => row.id > 0 && row['g0'].toLowerCase().includes(this.$store.state.filter?.toLowerCase()))
        .slice()
        .sort(this.sorter)
        .slice(0, this.itemsPerPage)
    },
    series() {
      return this.selectedFields.slice(0, 2).map((field) => {
        const text = this.fieldChoices.find((e) => e.value == field)
        const serie = { name: text.text, data: [] }
        this.chartableRows.forEach((row) => {
          if (row.id > 0 && row['g0'].toLowerCase().includes(this.$store.state.filter?.toLowerCase())) serie.data.push(row['i' + field])
        })
        return serie
      })
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: { tools: { download: false } },
          stacked: this.selectedFields.slice(0, 2).join(',') == '11,40' || this.selectedFields.slice(0, 2).join(',') == '40,11',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.chartableRows.map((row) => row['g0']),
        },
        yaxis: {
          tickAmount: 4,
        },
        fill: {
          opacity: 1,
        },
      }
    },
  },
  created() {
    eventBus.$on('updateVisualizations', () => {
      this.$nextTick(() => {
        this._computedWatchers.dataTableRows.run()
        this._computedWatchers.chartableRows.run()
        this._computedWatchers.chartOptions.run()
        this._computedWatchers.series.run()
        this.$forceUpdate()
      })
    })
    this.onResize()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    eventBus.$off('updateVisualizations')
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    sorterDesc(a, b) {
      if (reportVisualizationFunctions.isGroupColumn(this.tableSortBy[0])) return a[this.tableSortBy[0]].toLowerCase() < b[this.tableSortBy[0]].toLowerCase() ? 1 : -1
      const field = this.tableSortBy[0]
      return a[field] < b[field] ? 1 : -1
    },
    // Sorter function is used to sort data for apexchart to reflect the ordering in v-data-table
    sorter(a, b) {
      if (!this.tableSortBy) return 0
      if (this.tableSortDesc[0]) return this.sorterDesc(a, b)
      if (reportVisualizationFunctions.isGroupColumn(this.tableSortBy[0])) return a[this.tableSortBy[0]].toLowerCase() > b[this.tableSortBy[0]].toLowerCase() ? 1 : -1
      const field = this.tableSortBy[0]
      return a[field] > b[field] ? 1 : -1
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
  },
}
</script>
