<template>
  <div v-hotkey="keymap">
    <v-card-text class="pa-0 no-print">
      <p class="info white--text text-center my-0 py-1 rounded-t-lg">
        {{ swT('rowsselected') }}: {{ filteredDataTableRows.length }} / {{ Object.entries(aggregations).length }} {{ swT('date_range') }}:
        {{ capitalizeFirstLetter(swT($store.state.articleStatus.filterDateRange.name.toLowerCase())) }}
      </p>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items-per-page="itemsPerPage"
      :items="dataTableRows"
      :loading-text="swT('loading/calculating')"
      :show-expand="false"
      :show-select="false"
      :sort-by="tableSortBy"
      :sort-desc="tableSortDesc"
      :server-item-length="dataTableRows.length"
      class="elevation-1 mainTable"
      dense
      fixed-header
      hide-default-footer
      item-class="class"
      item-key="id"
      mobile-breakpoint="200"
      @contextmenu:row="activateMenu"
      @dblclick:row="activateMenu"
      @update:sort-by="$emit('update:sort-by', $event)"
      @update:sort-desc="$emit('update:sort-desc', $event)"
    >
      <template v-slot:body.prepend>
        <tr class="no-print">
          <td v-for="(header, i) in headers" :key="header.text">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-if="!header.value.startsWith('g')"
                  v-model="headerFilters[header.value]"
                  dense
                  clearable
                  small
                  hide-details
                  outlined
                  class="ma-1 pa-0"
                  :data-test="`stringColumnFilter-${i}`"
                  v-on="on"
                ></v-text-field>
              </template>
              <div class="d-flex flex-column">
                <span>{{ swT('all_without_X') }}</span>
                <span>{{ swT('all_greater_or_equal_to_X') }}</span>
                <span>{{ swT('all_lower_or_equal_to_X') }}</span>
                <span>{{ swT('all_greater_than_X') }}</span>
                <span>{{ swT('all_lower_than_X') }}</span>
              </div>
            </v-tooltip>
            <div v-if="header.value.startsWith('g')">
              <v-text-field
                v-if="!comboboxes.includes(i)"
                v-model="headerFilters[header.value]"
                append-icon="mdi-menu"
                dense
                clearable
                :data-test="`stringFilter-${i}`"
                small
                hide-details
                outlined
                class="ma-1 pa-0"
              >
                <template v-slot:append>
                  <v-icon style="cursor:pointer;" @click="comboboxes.push(i)">
                    {{ !headerFilters[header.value] || headerFilters[header.value].length === 0 ? 'mdi-alphabetical' : '' }}
                  </v-icon>
                </template>
              </v-text-field>
              <v-combobox
                v-else
                v-model="headerFilters[header.value]"
                :items="tableUIData.filterOptions[header.value]"
                dense
                auto-select-first
                hide-selected
                clearable
                small
                multiple
                hide-details
                outlined
                class="ma-1 pa-0"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip v-bind="attrs" :color="`info dark`" :data-test="`comboboxFilter-${i}`" :input-value="selected" label small>
                    <span class="pr-1">
                      {{ item }}
                    </span>
                    <v-icon small @click="parent.selectItem(item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
                <template v-slot:append>
                  <v-icon v-if="!headerFilters[header.value] || headerFilters[header.value].length === 0" style="cursor:pointer;" data-test="menu" @click="switchToTextField(i)">
                    mdi-menu
                  </v-icon>
                </template>
              </v-combobox>
            </div>
          </td>
        </tr>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ header, value }">
        {{ formatCell(header, value) }}
      </template>
    </v-data-table>
    <v-menu v-model="menuActivated" :position-x="contextMenuX" :position-y="contextMenuY" absolute offset-y rounded="lg">
      <v-list>
        <v-list-item v-for="(item, index) in tablePopupMenuItems" :key="index">
          <v-list-item-title @click="item.callback(item.payload)">
            <span :class="item.class">{{ item.title }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import { format } from 'date-fns'
import reportVisualizationFunctions from '../functions/reportVisualizationFunctions'
import worker from '../workers/worker-api'
import { eventBus } from '../main'
import globalStore from '@/store/globalStore'
import webServices from '../functions/webServicesFacade'

export default {
  props: ['withSubtotals', 'aggregations', 'selectedFields', 'fieldChoices', 'tableSortBy', 'tableSortDesc', 'dates', 'selectedGroups', 'itemsPerPage', 'groupChoices'],
  data() {
    return {
      swT,
      page: 1,
      menuActivated: false,
      contextMenuX: 0,
      contextMenuY: 0,
      tablePopupMenuItems: [],
      comboboxes: [], // List of comboboxes that are currently active
      headerFilters: {},
      showCombobox: false,
      transactionTypes: {
        '0': 'start_stock',
        '1': 'receiving',
        '2': 'sale',
        '3': 'transit',
        '4': 'change',
        '5': 'return_supplier',
        '6': 'return_customer',
        '7': 'credit_invoice',
        '8': 're_valuation',
        '9': 'transit',
        '10': 'start_shelf_stock',
        '14': 'purchase_order',
        '15': 'purchase_order_complete',
        '18': 'minimum_stock',
        '93': 'pre_sold',
        '94': 'pick_list',
        '95': 'pre_pick_list',
        '96': 'order',
        '97': 'pre_order',
        '98': 'offer',
      },
    }
  },
  computed: {
    tableUIData() {
      return reportVisualizationFunctions.buildTableUIData(this.aggregations, this.selectedFields, this.headers, this.dates, this.$store.state.filter?.toLowerCase())
    },
    pageCount() {
      return Math.ceil(this.filteredDataTableRows.length / this.itemsPerPage)
    },
    keymap() {
      return {
        'ctrl+>': this.nextPage,
        'ctrl+<': this.prevPage,
      }
    },
    headers() {
      const groupChoicesIndex = this.groupChoices.reduce((agg, e) => ({ ...agg, [e.value]: e }), {})

      const newHeaders = this.selectedGroups.map((group, index) => {
        const text = swT(group).replace('sku._', '')
        return {
          field: group,
          text: text,
          value: 'g' + index,
          align: 'start',
          sortable: true,
          fieldType: groupChoicesIndex[group].fieldType,
        }
      })
      const makeFootprintCalculations = newHeaders[0]?.field == 'sku.brand' && newHeaders[1]?.field == 'sku.FOOTPRINT'

      for (const i of this.selectedFields) {
        const text = this.fieldChoices.find((e) => e.value == i)
        if (text == undefined) continue
        newHeaders.push({
          text: text.text,
          value: 'v' + i,
          align: 'right',
          sortable: true,
          filterable: false,
          fieldType: text.fieldType,
        })
        if (makeFootprintCalculations && [0, 1, 'currency'].includes(text.fieldType))
          newHeaders.push({
            text: 'ρ' + text.text,
            value: 'v' + i + 1000,
            align: 'right',
            sortable: true,
            filterable: false,
            fieldType: text.fieldType,
          })
      }
      return newHeaders
    },
    customFilter() {
      // Callback function to filter out the subtotals
      return function(row, showSubtotals) {
        return row[0].includes('Σ') && !showSubtotals ? false : true
      }
    },
    // Filters groups with filtrex and columns with our own logic
    filteredDataTableRows() {
      if (!this.aggregations && !this.tableUIData.intermediateAggregations) return []

      let groupFiltrexExpression
      const { columnsFilter, groupersFilter } = reportVisualizationFunctions.buildGroupersAndColumnsFilter(this.headerFilters)

      if (Object.keys(groupersFilter).length) groupFiltrexExpression = reportVisualizationFunctions.compileFiltrexExpression(groupersFilter)

      return reportVisualizationFunctions.filterUIDataTableRows(
        this.tableUIData.intermediateAggregations,
        groupFiltrexExpression,
        columnsFilter,
        this.customFilter,
        this.$store.state.articleStatus.showSubtotals
      )
    },
    allDataTableRows() {
      let rowId = 0
      if (!this.aggregations && !this.tableUIData.intermediateAggregations) return []

      return this.filteredDataTableRows
        .map((oneAggregatedRow) => {
          const returnValue = oneAggregatedRow[2]
          returnValue.id = rowId++
          return returnValue
        })
        .sort(this.sorter)
    },
    dataTableRows() {
      if (!this.aggregations && !this.tableUIData.intermediateAggregations) return []
      // We try to ignore totals and check if there are 2 rows, the total row has a class property
      const rowsWithoutClass = this.allDataTableRows.filter((row) => !row.class).length
      if (rowsWithoutClass === 2) return this.allDataTableRows.concat(this.calculateDiffRow(this.allDataTableRows))
      return this.allDataTableRows.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage)
    },
  },
  watch: {
    filteredDataTableRows() {
      this.$store.state.rowsInfo = {
        filtered: this.filteredDataTableRows.length,
        total: Object.keys(this.aggregations).length,
      }
    },
    pageCount: {
      handler() {
        if (this.page > this.pageCount) this.page = 1
        if ((this.$route.query?.reportViz === 'table' || this.$route.query?.reportViz === 'customertable') && this.$store.state.reportsFilterFullUI)
          this.$store.state.paginationTotalPages = this.pageCount
        else this.$store.state.paginationTotalPages = -1
      },
      immediate: true,
    },
    '$store.state.reportsFilterFullUI': {
      handler() {
        if ((this.$route.query?.reportViz === 'table' || this.$route.query?.reportViz === 'customertable') && this.$store.state.reportsFilterFullUI)
          this.$store.state.paginationTotalPages = this.pageCount
        else this.$store.state.paginationTotalPages = -1
      },
    },
    '$store.state.paginationCurrentPage': {
      handler() {
        this.page = this.$store.state.paginationCurrentPage
      },
    },
    '$store.state.filter': {
      handler() {
        this.page = 1
      },
    },
    $props: {
      handler() {
        this._computedWatchers.dataTableRows.run()
        this.$forceUpdate()
      },
      deep: true,
    },
  },
  created() {
    eventBus.$on('updateVisualizations', () => {
      this.$nextTick(() => {
        this._computedWatchers.tableUIData.run()
        this._computedWatchers.filteredDataTableRows.run()
        this._computedWatchers.allDataTableRows.run()
        this._computedWatchers.dataTableRows.run()
        this.$forceUpdate()
      })
    })
    eventBus.$on('copyToExcel', () => this.copyToExcel())
    eventBus.$on('getPostAggFilters', (x) => this.getPostAggFilters(x))
  },
  beforeDestroy() {
    eventBus.$off('updateVisualizations')
    eventBus.$off('copyToExcel')
    eventBus.$off('getPostAggFilters')
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    calculateDiffRow(rows) {
      const rowsToCompare = rows.filter((row) => !row.class)
      // filter() guarantees order, so table order is preserved
      const row1 = rowsToCompare[0]
      const row2 = rowsToCompare[1]
      const diffRow = {}
      for (const key of Object.keys(row1)) {
        if (key.startsWith('v')) diffRow[key] = row2[key] - row1[key]
      }
      diffRow.class = 'font-weight-black'
      diffRow.id = rows.length
      diffRow['g0'] = swT('diff')
      return [diffRow]
    },
    switchToTextField(i) {
      this.comboboxes = this.comboboxes.filter((e) => e != i)
    },
    goToRoute(route) {
      this.$router.push(route)
    },
    setFilter(filter) {
      this.$emit('setManualFilter', filter)
    },
    formatCell(header, value) {
      const fieldType = header.fieldType
      if (reportVisualizationFunctions.isGroupColumn(header.value)) {
        if (fieldType == 'date') {
          const unixTime = parseInt(value)
          if (unixTime) return format(unixTime, 'yyyy-MM-dd HH:mm:ss')
          // if (unixTime) return format(unixTime, 'P ppp')
          // if (unixTime) return '' + unixTime
        }
        if (fieldType == 'transactionType') return this.transactionTypes[value]
        if (fieldType == 'campaignKeys')
          return (value || '')
            .split(',')
            .map((key) => globalStore.getLatestCollectionObject('campaign')[key]?.campaignName || '')
            .join(',')
        return value
      }
      if (fieldType == 'date') return format(value, 'yyyy-MM-dd')
      if (fieldType == 'percentage') return value === Infinity || value === -Infinity || isNaN(value) ? '-' : value.toFixed(2) + '%'
      if (fieldType == 'currency') return '€' + value.toFixed(2)
      if (!value) return 0
      return value.toFixed(fieldType)
    },
    activateMenu(e, context) {
      e.preventDefault()
      const brandGroupIndex = this.selectedGroups.indexOf('sku.brand')
      const articleCodeGroupIndex = this.selectedGroups.indexOf('sku.articleCode')
      this.tablePopupMenuItems = []
      if (brandGroupIndex >= 0) {
        const brandGroup = 'g' + brandGroupIndex
        const brand = context.item[brandGroup]
        if (brand) {
          this.tablePopupMenuItems.push({
            title: 'Ga naar merk:' + brand,
            callback: this.goToRoute,
            class: 'warning--text',
            payload: { path: 'products', query: { brandId: brand } },
          })
          if (articleCodeGroupIndex >= 0) {
            const articleCodeGroup = 'g' + articleCodeGroupIndex
            const articleCode = context.item[articleCodeGroup]
            if (articleCode) {
              this.tablePopupMenuItems.push({
                title: 'Ga naar product: ' + articleCode,
                callback: this.goToRoute,
                class: 'warning--text',
                payload: {
                  path: 'products',
                  query: { brandId: brand, productId: articleCode },
                },
              })

              this.tablePopupMenuItems.push({
                title: 'Producthistorie: ' + articleCode,
                callback: this.goToRoute,
                class: 'primary--text',
                payload: {
                  path: 'reports',
                  query: {
                    type: 'articlestatus',
                    groups: 'sku.articleCode,sku.colorFamily,sku.size,transaction.ean,transaction.time,transaction.type,transaction.docnr,wh.name',
                    filter: `sku.articleCode == "${articleCode}"`,
                    fields: '6,8,12,14,41',
                    period: 'LASTMONTH',
                    reportViz: 'table',
                  },
                },
              })

              this.tablePopupMenuItems.push({
                title: 'Voorraad: ' + articleCode,
                callback: this.goToRoute,
                class: 'primary--text',
                payload: {
                  path: 'reports',
                  query: {
                    type: 'articlestatus',
                    groups: '',
                    filter: `sku.articleCode == "${articleCode}"`,
                    fields: '41',
                    period: 'TODAY',
                    reportViz: 'matrix',
                  },
                },
              })
            }
          }
        }
      }
      this.selectedGroups.forEach((group, index) => {
        if ('transaction.type,transaction.time,wh.name'.includes(group)) return
        const propName = 'g' + index
        const propValue = context.item[propName]
        if (propValue)
          this.tablePopupMenuItems.push({
            title: 'Filter op ' + group + ':' + propValue,
            callback: this.setFilter,
            payload: group + ' == "' + propValue + '"',
          })
      })
      this.menuActivated = false
      this.contextMenuX = e.clientX
      this.contextMenuY = e.clientY
      this.$nextTick(() => {
        this.menuActivated = true
      })
    },
    async getPostAggFilters({ callbackFn }) {
      // We only need to repsond on the active component
      if (this._inactive == true) return
      const rows = this.filteredDataTableRows
      callbackFn(
        rows.reduce((agg, row) => {
          agg[row[0]] = true
          return agg
        }, {})
      )
    },
    async copyToExcel() {
      this.$store.state.exportBusy = true
      const columnNameMap = this.headers.reduce((agg, item) => {
        agg[item.value] = item.text
        return agg
      }, {})
      const xlsx = this.allDataTableRows.map((row) => {
        return Object.entries(row).reduce((agg, item) => {
          const mappedColumnName = columnNameMap[item[0]]
          if (mappedColumnName) {
            agg[mappedColumnName] = item[1]
          }
          return agg
        }, {})
      })
      const url = await worker.xlsxWrite(xlsx)
      await webServices.forceDownload(url, 'articleStatus.xlsx')
      this.$store.state.exportBusy = false
    },
    nextPage() {
      if (this.page < this.pageCount) this.$store.state.paginationCurrentPage++
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    prevPage(e) {
      if (this.page > 1) this.$store.state.paginationCurrentPage--
    },
    sorterDesc(a, b) {
      if (reportVisualizationFunctions.isGroupColumn(this.tableSortBy[0])) return a[this.tableSortBy[0]]?.toLowerCase() < b[this.tableSortBy[0]]?.toLowerCase() ? 1 : -1
      const field = this.tableSortBy[0]
      return a[field] < b[field] ? 1 : -1
    },
    // Sorter function is used to sort data to reflect the ordering in v-data-table
    sorter(a, b) {
      if (!this.tableSortBy) return 0
      if (this.tableSortDesc[0]) return this.sorterDesc(a, b)
      if (reportVisualizationFunctions.isGroupColumn(this.tableSortBy[0])) return a[this.tableSortBy[0]]?.toLowerCase() > b[this.tableSortBy[0]]?.toLowerCase() ? 1 : -1
      const field = this.tableSortBy[0]
      return a[field] > b[field] ? 1 : -1
    },
  },
}
</script>

<style lang="scss">
.subtotal {
  background-color: #e9e9e9;
  font-weight: bold;
}
</style>
