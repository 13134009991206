import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}]},[_c(VCardText,{staticClass:"pa-0 no-print"},[_c('p',{staticClass:"info white--text text-center my-0 py-1 rounded-t-lg"},[_vm._v(" "+_vm._s(_vm.swT('rowsselected'))+": "+_vm._s(_vm.filteredDataTableRows.length)+" / "+_vm._s(Object.entries(_vm.aggregations).length)+" "+_vm._s(_vm.swT('date_range'))+": "+_vm._s(_vm.capitalizeFirstLetter(_vm.swT(_vm.$store.state.articleStatus.filterDateRange.name.toLowerCase())))+" ")])]),_c(VDataTable,{staticClass:"elevation-1 mainTable",attrs:{"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"items":_vm.dataTableRows,"loading-text":_vm.swT('loading/calculating'),"show-expand":false,"show-select":false,"sort-by":_vm.tableSortBy,"sort-desc":_vm.tableSortDesc,"server-item-length":_vm.dataTableRows.length,"dense":"","fixed-header":"","hide-default-footer":"","item-class":"class","item-key":"id","mobile-breakpoint":"200"},on:{"contextmenu:row":_vm.activateMenu,"dblclick:row":_vm.activateMenu,"update:sort-by":function($event){return _vm.$emit('update:sort-by', $event)},"update:sort-desc":function($event){return _vm.$emit('update:sort-desc', $event)}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',{staticClass:"no-print"},_vm._l((_vm.headers),function(header,i){return _c('td',{key:header.text},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!header.value.startsWith('g'))?_c(VTextField,_vm._g({staticClass:"ma-1 pa-0",attrs:{"dense":"","clearable":"","small":"","hide-details":"","outlined":"","data-test":("stringColumnFilter-" + i)},model:{value:(_vm.headerFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.headerFilters, header.value, $$v)},expression:"headerFilters[header.value]"}},on)):_vm._e()]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.swT('all_without_X')))]),_c('span',[_vm._v(_vm._s(_vm.swT('all_greater_or_equal_to_X')))]),_c('span',[_vm._v(_vm._s(_vm.swT('all_lower_or_equal_to_X')))]),_c('span',[_vm._v(_vm._s(_vm.swT('all_greater_than_X')))]),_c('span',[_vm._v(_vm._s(_vm.swT('all_lower_than_X')))])])]),(header.value.startsWith('g'))?_c('div',[(!_vm.comboboxes.includes(i))?_c(VTextField,{staticClass:"ma-1 pa-0",attrs:{"append-icon":"mdi-menu","dense":"","clearable":"","data-test":("stringFilter-" + i),"small":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VIcon,{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.comboboxes.push(i)}}},[_vm._v(" "+_vm._s(!_vm.headerFilters[header.value] || _vm.headerFilters[header.value].length === 0 ? 'mdi-alphabetical' : '')+" ")])]},proxy:true}],null,true),model:{value:(_vm.headerFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.headerFilters, header.value, $$v)},expression:"headerFilters[header.value]"}}):_c(VCombobox,{staticClass:"ma-1 pa-0",attrs:{"items":_vm.tableUIData.filterOptions[header.value],"dense":"","auto-select-first":"","hide-selected":"","clearable":"","small":"","multiple":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c(VChip,_vm._b({attrs:{"color":"info dark","data-test":("comboboxFilter-" + i),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-1"},[_vm._v(" "+_vm._s(item)+" ")]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1)]}},{key:"append",fn:function(){return [(!_vm.headerFilters[header.value] || _vm.headerFilters[header.value].length === 0)?_c(VIcon,{staticStyle:{"cursor":"pointer"},attrs:{"data-test":"menu"},on:{"click":function($event){return _vm.switchToTextField(i)}}},[_vm._v(" mdi-menu ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.headerFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.headerFilters, header.value, $$v)},expression:"headerFilters[header.value]"}})],1):_vm._e()],1)}),0)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var header = ref.header;
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCell(header, value))+" ")]}}})],null,true)}),_c(VMenu,{attrs:{"position-x":_vm.contextMenuX,"position-y":_vm.contextMenuY,"absolute":"","offset-y":"","rounded":"lg"},model:{value:(_vm.menuActivated),callback:function ($$v) {_vm.menuActivated=$$v},expression:"menuActivated"}},[_c(VList,_vm._l((_vm.tablePopupMenuItems),function(item,index){return _c(VListItem,{key:index},[_c(VListItemTitle,{on:{"click":function($event){return item.callback(item.payload)}}},[_c('span',{class:item.class},[_vm._v(_vm._s(item.title))])])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }