<template>
  <div v-hotkey="keymap">
    <v-stepper v-model="localFilterStepperModel" non-linear vertical class="pb-2 mt-5">
      <v-card-text class="pa-0">
        <p class="info white--text text-center py-1">{{ $store.state.rowsInfo.filtered }} / {{ $store.state.rowsInfo.total }} {{ swT('rowsselected') }}</p>
      </v-card-text>
      <v-stepper-step step="1" class="py-3" complete editable edit-icon="mdi-calendar">
        <div class="d-flex justify-space-between">
          <div>{{ swT('select_a_period') }}</div>
          <div>
            <span :data-test="`anyDate-${localDate.name}`">{{ swT(localDate.name.toLowerCase()).toUpperCase() }}</span>
            <span v-if="$vuetify.breakpoint.smAndUp" :data-test="`${dates}`">
              &nbsp;&nbsp;&nbsp; {{ $store.state.articleStatus.filterDateRange.dates[0] }} - {{ $store.state.articleStatus.filterDateRange.dates[1] }}
            </span>
          </div>
        </div>
      </v-stepper-step>

      <v-stepper-content class="my-0 py-0" step="1">
        <div class="my-1">
          <v-row class="d-flex justify-center">
            <v-col class="d-flex flex-column">
              <v-btn-toggle v-model="toggleModel" dense class="pb-3 tgl-group" color="info">
                <v-btn
                  v-for="relativeDate in ['last365', 'lastmonth', 'thismonth', 'lastweek']"
                  :key="relativeDate"
                  class="ellipsis"
                  small
                  @click="updateDates({ period: relativeDate })"
                >
                  {{ swT(relativeDate) }}
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle v-model="toggleModel" dense class="pb-3 tgl-group" color="info">
                <v-btn
                  v-for="relativeDay in ['thisweek', 'yesterday', 'today']"
                  :key="relativeDay"
                  class="ellipsis"
                  small
                  :data-test="`relativeDay-${relativeDay}`"
                  @click="updateDates({ period: relativeDay })"
                >
                  {{ swT(relativeDay) }}
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle v-model="toggleModel" dense class="pb-3 tgl-group" color="info">
                <v-btn v-for="quarter in [1, 2, 3, 4]" :key="'Q' + quarter" small @click="updateDates({ quarter: 'Q' + quarter, year: dates[0].slice(0, 4) })">
                  Q{{ quarter }}
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle v-model="toggleModel" dense class="tgl-group" color="info">
                <v-btn v-for="year in $store.getters.years" :key="year" small class="ellipsis" :data-test="year" @click="updateDates({ wholeYear: '' + year })">
                  {{ year }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <v-btn color="info" @click="$store.state.reportsFilterFullUI = true">
                <v-icon small>mdi-flag-checkered</v-icon>
                {{ swT('show_data') }}
              </v-btn>
              <div>
                <v-btn class="ma-2" elevation="2" color="info" fab small @click="localFilterStepperModel = 2">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-content>

      <v-stepper-step step="2" class="py-3" complete editable edit-icon="mdi-filter" data-test="filters">
        {{ swT('select_filters') }}
      </v-stepper-step>

      <v-stepper-content class="my-0 py-0" step="2">
        <div class="my-1">
          <v-row>
            <v-col>
              <div class="d-flex align-center">
                <v-btn outlined small class="mr-2 px-1" min-width="inherit" color="rgba(0,0,0,0.5)"><v-icon>mdi-equal</v-icon></v-btn>
                <span class="my-1">{{ swT('click_on_equal') }}</span>
              </div>

              <div class="d-flex align-center">
                <v-btn outlined small class="mr-2 px-1" min-width="inherit" color="rgba(0,0,0,0.5)"><v-icon>mdi-not-equal-variant</v-icon></v-btn>
                <span class="my-1">{{ swT('click_on_not_equal') }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" class="text-center">
              <v-autocomplete
                v-model="$store.state.articleStatus.warehouseFilter"
                outlined
                hide-details
                :items="getItems('warehouse')"
                :label="swT('selectwarehouses')"
                multiple
                small-chips
                deletable-chips
                clearable
                dense
                data-test="warehouseFilter"
              >
                <template v-slot:prepend-inner>
                  <v-icon
                    :color="$store.state.articleStatus.warehouseFilterEquals ? 'primary' : 'error'"
                    style="cursor:pointer"
                    @click="$store.state.articleStatus.warehouseFilterEquals = !$store.state.articleStatus.warehouseFilterEquals"
                  >
                    {{ $store.state.articleStatus.warehouseFilterEquals ? 'mdi-equal' : 'mdi-not-equal-variant' }}
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="text-center">
              <v-autocomplete
                v-model="$store.state.articleStatus.brandFilter"
                outlined
                hide-details
                :items="getItems('brand')"
                :label="swT('selectbrands')"
                multiple
                small-chips
                deletable-chips
                clearable
                dense
              >
                <template v-slot:prepend-inner>
                  <v-icon
                    :color="$store.state.articleStatus.brandFilterEquals ? 'primary' : 'error'"
                    style="cursor:pointer"
                    @click="$store.state.articleStatus.brandFilterEquals = !$store.state.articleStatus.brandFilterEquals"
                  >
                    {{ $store.state.articleStatus.brandFilterEquals ? 'mdi-equal' : 'mdi-not-equal-variant' }}
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="text-center">
              <v-autocomplete
                v-model="$store.state.articleStatus.collectionFilter"
                outlined
                hide-details
                :items="getItems('collection')"
                :label="swT('selectcollections')"
                multiple
                small-chips
                deletable-chips
                clearable
                dense
              >
                <template v-slot:prepend-inner>
                  <v-icon
                    :color="$store.state.articleStatus.collectionFilterEquals ? 'primary' : 'error'"
                    style="cursor:pointer"
                    @click="$store.state.articleStatus.collectionFilterEquals = !$store.state.articleStatus.collectionFilterEquals"
                  >
                    {{ $store.state.articleStatus.collectionFilterEquals ? 'mdi-equal' : 'mdi-not-equal-variant' }}
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" class="text-center">
              <v-autocomplete
                v-model="$store.state.articleStatus.productGroupFilter"
                outlined
                hide-details
                :items="getItems('articleGroup')"
                :label="swT('selectproductgroups')"
                multiple
                small-chips
                deletable-chips
                clearable
                dense
              >
                <template v-slot:prepend-inner>
                  <v-icon
                    :color="$store.state.articleStatus.productGroupFilterEquals ? 'primary' : 'error'"
                    style="cursor:pointer"
                    @click="$store.state.articleStatus.productGroupFilterEquals = !$store.state.articleStatus.productGroupFilterEquals"
                  >
                    {{ $store.state.articleStatus.productGroupFilterEquals ? 'mdi-equal' : 'mdi-not-equal-variant' }}
                  </v-icon>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <v-btn color="info" @click="$store.state.reportsFilterFullUI = true">
                <v-icon small>mdi-flag-checkered</v-icon>
                {{ swT('show_data') }}
              </v-btn>
              <div>
                <v-btn class="ma-2 mr-0 ml-4" elevation="2" color="info" fab small @click="localFilterStepperModel--">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="ma-2" elevation="2" color="info" fab small @click="localFilterStepperModel++">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-content>

      <v-stepper-step step="3" class="py-3" complete editable edit-icon="mdi-file-tree" data-test="groupers">
        {{ swT('select_grouping_options') }}
      </v-stepper-step>

      <v-stepper-content class="my-0 py-0" step="3">
        <div class="my-2">
          <v-row>
            <v-col class="text-center" cols="12">
              <v-combobox
                v-model="$store.state.articleStatus.selectedGroups"
                :items="$store.getters.translatedReportGroupColumns"
                outlined
                auto-select-first
                hide-selected
                hide-details
                multiple
                small-chips
                deletable-chips
                clearable
                dense
                data-test="selectedGroups"
                prepend-inner-icon="mdi-file-tree"
                :label="swT('selectgroups')"
                @change="manageWithSubtotals($store.state.articleStatus.selectedGroups.length)"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip v-bind="attrs" :input-value="selected" label small>
                    <span class="pr-2" data-cy="columnChip">{{ swT(item.text) }}</span>
                    <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <span :data-test="`${item.text}`">{{ swT(item.text) }}</span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ swT('noData') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <v-btn color="info" @click="$store.state.reportsFilterFullUI = true">
                <v-icon small>mdi-flag-checkered</v-icon>
                {{ swT('show_data') }}
              </v-btn>
              <div>
                <v-btn class="ma-2 mr-0 ml-4" elevation="2" color="info" fab small @click="localFilterStepperModel--">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="ma-2" elevation="2" color="info" fab small @click="localFilterStepperModel++">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-content>

      <v-stepper-step step="4" class="py-3" complete editable edit-icon="mdi-table-column" data-test="columns">
        {{ swT('select_columns') }}
      </v-stepper-step>

      <v-stepper-content class="my-0 py-0" step="4">
        <div class="my-2">
          <v-row>
            <v-col class="text-center" cols="12">
              <v-combobox
                v-model="$store.state.articleStatus.selectedFields"
                :items="$store.getters.translatedReportColumns"
                data-test="selectedFields"
                outlined
                hide-details
                hide-selected
                auto-select-first
                multiple
                clearable
                dense
                :label="swT('select_columns')"
                prepend-inner-icon="mdi-table-column"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip v-bind="attrs" :input-value="selected" label small>
                    <span class="pr-2" :data-test="`groupChip-${item.text}`">{{ swT(item.text) }}</span>
                    <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                  </v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <span :data-test="`columnChip-${item.text}`">{{ swT(item.text) }}</span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ swT('noData') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <v-btn color="info" @click="$store.state.reportsFilterFullUI = true">
                <v-icon small>mdi-flag-checkered</v-icon>
                {{ swT('show_data') }}
              </v-btn>
              <div>
                <v-btn class="ma-2 mr-0 ml-4" elevation="2" color="info" fab small @click="localFilterStepperModel--">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="ma-2" elevation="2" color="info" fab small @click="localFilterStepperModel++">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-content>

      <v-stepper-step v-if="!$vuetify.breakpoint.smAndUp" step="5" class="py-3" complete editable edit-icon="mdi-tools" data-test="misc">
        {{ swT('miscellaneous') }}
      </v-stepper-step>

      <v-stepper-content v-if="!$vuetify.breakpoint.smAndUp" class="my-0 py-0" step="5">
        <div class="my-1">
          <v-row class="mt-1">
            <v-col cols="12" sm="3">
              <v-text-field
                outlined
                hide-details
                dense
                class="my-1"
                :value="$store.state.articleStatus.itemsPerPage"
                :label="swT('rowsperpage')"
                type="number"
                min="1"
                max="1000"
                data-cy="rowsPerPage"
                :data-test="`rowsPerPage-${$store.state.articleStatus.itemsPerPage}`"
                @input="$store.state.articleStatus.itemsPerPage = Math.max(1, parseInt($event, 10) || 1)"
              ></v-text-field>
              <v-btn
                class="my-1"
                small
                hide-details
                :label="swT('withSubtotals')"
                data-cy="subtotals"
                :data-test="`subtotals${$store.state.articleStatus.withSubtotals}`"
                @click.stop="$store.state.articleStatus.showSubtotals = !$store.state.articleStatus.showSubtotals"
              >
                {{ subtotalsButtonText }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex justify-space-between align-center">
              <v-btn color="info" @click="$store.state.reportsFilterFullUI = true">
                <v-icon small>mdi-flag-checkered</v-icon>
                {{ swT('show_data') }}
              </v-btn>
              <div>
                <v-btn class="ma-2 mr-0 ml-4" elevation="2" color="info" fab small @click="localFilterStepperModel--">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn class="ma-2" elevation="2" color="info" fab small @click="localFilterStepperModel = 1">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import globalStore from '../store/globalStore'

export default {
  props: ['anyDate', 'dates'],
  data() {
    return {
      swT,
      localConsistencyChecked: null,
      localFilterStepperModel: 1,
      toggleModel: null,
    }
  },
  computed: {
    subtotalsButtonText() {
      return this.$store.state.articleStatus.showSubtotals ? this.swT('withoutSubtotals') : this.swT('withSubtotals')
    },
    localDate() {
      return this.$store.state.articleStatus.filterDateRange
    },
    keymap() {
      return {
        'ctrl+>': this.nextStep,
        'ctrl+<': this.previousStep,
      }
    },
  },
  created() {
    this.manageWithSubtotals(this.$store.state.articleStatus.selectedGroups.length)
  },
  methods: {
    nextStep() {
      if (this.localFilterStepperModel === null) this.localFilterStepperModel = 2
      else if (this.localFilterStepperModel === 5) this.localFilterStepperModel = 1
      else this.localFilterStepperModel++
    },
    previousStep() {
      if (this.localFilterStepperModel > 1) this.localFilterStepperModel--
      else this.localFilterStepperModel = 5
    },
    // The intention is to always calculate subtotals for the `table` reportViz, for 2 groupers and up
    // The user can only control whether to show them or not through the UI
    // If other reports will use subtotals, maybe we can rename the store module `articleStatus` to something more generic like `reports`
    manageWithSubtotals(value) {
      if (value > 1 && this.$store.state.reportViz == 'table') this.$store.state.articleStatus.withSubtotals = true
      else this.$store.state.articleStatus.withSubtotals = false
    },
    getItems(items) {
      return globalStore.getItems(items, this.$store)
    },
    updateDates(dates) {
      this.$emit('updateDates', dates)
      this.$nextTick(() => {
        this.toggleModel = null
      })
    },
    showData() {
      this.$store.state.reportsFilterFullUI = true
    },
  },
}
</script>
