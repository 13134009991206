<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader type="list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line"></v-skeleton-loader>
    </div>
    <div v-else>
      <v-card :class="$route.query.type == 'stock' ? 'mt-2' : 'mt-4'" class="pa-2 mb-1" elevation="4">
        <v-row v-if="showImage">
          <v-col cols="12" sm="3" lg="2">
            <v-card>
              <v-expand-transition appear>
                <v-img :src="image(product, 600)" :lazy-src="image(product, 75)" aspect-ratio="1" class="grey lighten-2" contain data-test="productImage" />
              </v-expand-transition>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9" lg="10">
            <div class="d-flex align-center pb-1">
              <!-- transition must have exactly 1 child element, so use a wrapper for multiple slot elements -->
              <transition appear name="slide-x-transition">
                <slot name="left" :product="product"></slot>
              </transition>
              <v-spacer></v-spacer>
              <transition appear name="slide-x-reverse-transition">
                <slot name="right" :product="product"></slot>
                <slot name="rightIndex" :idx="matrixIndex"></slot>
              </transition>
            </div>
            <swMatrix
              :allow-negatives="allowNegatives"
              :article-code-supplier="product.SKUS[0].articleCodeSupplier"
              :can-multiply-all-cell-values="canMultiplyAllCellValues"
              :can-set-all-cell-values="canSetAllCellValues"
              :fixed-header="fixedHeader"
              :headers="product.MATRIX.headers"
              :height="height"
              :hide-empty-rows="hideEmptyRows"
              :hide-zeroes="hideZeroes"
              :is-editing-stock="isEditingStock"
              :readonly="readonly"
              :show-eye="showEye"
              :rows="product.MATRIX.rows"
              :show-negative-values="showNegativeValues"
              :show-positive-values="showPositiveValues"
              @update="updateCell"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <div class="d-flex align-center pb-1">
              <!-- transition must have exactly 1 child element, so use a wrapper for multiple slot elements -->
              <transition appear name="slide-x-transition">
                <slot name="left" :product="product"></slot>
              </transition>
              <v-spacer></v-spacer>
              <transition appear name="slide-x-reverse-transition">
                <slot name="right" :product="product"></slot>
              </transition>
            </div>
            <swMatrix
              :allow-negatives="allowNegatives"
              :article-code-supplier="product.SKUS[0].articleCodeSupplier"
              :can-multiply-all-cell-values="canMultiplyAllCellValues"
              :can-set-all-cell-values="canSetAllCellValues"
              :fixed-header="fixedHeader"
              :headers="product.MATRIX.headers"
              :height="height"
              :hide-empty-rows="hideEmptyRows"
              :hide-zeroes="hideZeroes"
              :is-editing-stock="isEditingStock"
              :readonly="readonly"
              :rows="product.MATRIX.rows"
              :show-eye="showEye"
              :show-negative-values="showNegativeValues"
              :show-positive-values="showPositiveValues"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import globalStore from '../store/globalStore'
import swMatrix from '../components/swMatrix.vue'
import tools from '../functions/tools'

export default {
  components: { swMatrix },
  props: [
    'allowNegatives',
    'canMultiplyAllCellValues',
    'canSetAllCellValues',
    'fixedHeader',
    'height',
    'hideEmptyRows',
    'hideZeroes',
    'isEditingStock',
    'matrixIndex',
    'product',
    'readonly',
    'showImage',
    'showEye',
    'showNegativeValues',
    'showPositiveValues',
  ],
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.loading = false
  },
  methods: {
    image(product, width) {
      const sku = product.SKUS[0]
      const productKey = `${sku.brand}__${sku.articleCodeSupplier}`
      const index = globalStore.getIndexedProducts()
      const images = index[productKey]?.[0]?.images
      if (!images) return ''
      return this.imageSrc(images.split(',')[0], width)
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
    updateCell(key, value, group, articleCodeSupplier) {
      this.$emit('update', key, value, group, articleCodeSupplier)
    },
  },
}
</script>
