import { articleStatus } from '@softwear/latestcollectioncore'
import reportVisualizationFunctions from '../functions/reportVisualizationFunctions'
import { format } from 'date-fns'

function buildDataStructure(inputData, dates: any, userSelectedGroupers: Record<string, any>[]) {
  const dateRange = articleStatus.getDateRangeFromPicker(dates)
  const timeFrame = dateRange.endTimeStamp - dateRange.beginTimeStamp
  const insight = {}
  const keyIndex = [] // This is used to keep track of the order of the keys in the insight object for the UI (sorting, pagination)

  Object.entries(inputData).forEach(([groupers, vector]) => {
    const vectorArray = [].slice.call(vector)
    articleStatus.postAgg(vectorArray, timeFrame)
    const groupersArray = groupers.split('\t')
    const isArticleCodeSupplierTotal = groupers.endsWith(' Σ')
    // If the last element of the groupersArray is 'Σ', then it is a total row
    // This means it has one less grouper than the other rows
    if (isArticleCodeSupplierTotal) {
      const mainGrouperKey = groupersArray.join('__').slice(0, -2)
      if (!insight[mainGrouperKey]) {
        //@ts-ignore
        keyIndex.push(mainGrouperKey)
        insight[mainGrouperKey] = {
          tableKPI: vectorArray,
        }
      }
      insight[mainGrouperKey].tableKPI = vectorArray
    }

    if (!isArticleCodeSupplierTotal) {
      const tableGrouper = groupersArray[groupersArray.length - 1]
      const mainGrouperKey = groupersArray.slice(0, -1).join('__')

      if (!insight[mainGrouperKey]) {
        //@ts-ignore
        keyIndex.push(mainGrouperKey)
        insight[mainGrouperKey] = {
          tableData: {},
        }
      }

      if (!insight[mainGrouperKey].tableData) {
        insight[mainGrouperKey].tableData = {}
      }

      insight[mainGrouperKey].tableData[tableGrouper] = vectorArray

      // This property gives us more understanding of how the table should be formatted
      if (!insight[mainGrouperKey].tableKey) {
        insight[mainGrouperKey].tableKey = userSelectedGroupers[userSelectedGroupers.length - 1].value
      }

      if (!insight[mainGrouperKey].rowMeta) {
        insight[mainGrouperKey].rowMeta = userSelectedGroupers.reduce((acc, curr, index) => {
          acc[curr.value] = groupersArray[index]
          return acc
        }, {})
      }
    }
  })
  return { insight, keyIndex }
}

function paginate(localKeyIndex, localInsightData, currentPage, itemsPerPage) {
  // The index of the data that is on the screen
  const onScreenIndex = localKeyIndex.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
  // The data that is on the screen
  const onScreenData = {}
  onScreenIndex.forEach((key) => {
    onScreenData[key] = localInsightData[key]
  })
  return onScreenData
}

function buildUIData(localKeyIndex, localInsightData, currentPage, itemsPerPage) {
  // This is an intermediary step between calculating the insight data and displaying it where we can decide what/how to display
  const paginationIndex = localKeyIndex.sort((a, b) => a.localeCompare(b)).filter((grouperKey) => !(grouperKey === '' || grouperKey.includes(`~ ?`))) // removes total and ~ ?__~ ?__~ ?
  return paginate(paginationIndex, localInsightData, currentPage, itemsPerPage)
}

function appLevelFilterItems(keyIndex, userFilter) {
  const filterArray = userFilter.split(/[\s,]+/).filter((el) => el)
  return keyIndex.filter((key) => filterArray.every((word) => key.toLowerCase().includes(word.toLowerCase())))
}

function formatKpi(fieldType, value) {
  if (fieldType == 'date') return format(value, 'yyyy-MM-dd')
  if (fieldType == 'percentage') return value === Infinity || value === -Infinity || isNaN(value) ? '-' : value.toFixed(2) + '%'
  if (fieldType == 'currency') return '€ ' + value.toFixed(2)
  return value.toFixed(fieldType)
}
export default {
  buildDataStructure,
  buildUIData,
  appLevelFilterItems,
  formatKpi,
}
