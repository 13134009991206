import Worker from 'worker-loader!./worker'
import * as Comlink from 'comlink'

const worker = Comlink.wrap(new Worker())

const xlsxRead = worker.xlsxRead
const xlsxWrite = worker.xlsxWrite
const xlsxBlob = worker.xlsBlob
export default {
  xlsxRead,
  xlsxWrite,
  xlsxBlob,
}
