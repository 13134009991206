import { format, subDays, subMonths, lastDayOfMonth, subWeeks, startOfWeek, endOfWeek } from 'date-fns'

function calculateYear(year) {
  const now = new Date()
  if (year == now.getFullYear()) return [year + '-01-01', format(now, 'yyyy-MM-dd')]
  return [year + '-01-01', year + '-12-31']
}

function calculateRelativePeriod(relativePeriod) {
  const now = new Date()

  if (relativePeriod == 'YESTERDAY') return [format(subDays(now, 1), 'yyyy-MM-dd'), format(subDays(now, 1), 'yyyy-MM-dd')]
  if (relativePeriod == 'TODAY') return [format(now, 'yyyy-MM-dd'), format(now, 'yyyy-MM-dd')]
  if (relativePeriod == 'LAST365') return [format(subDays(now, 364), 'yyyy-MM-dd'), format(now, 'yyyy-MM-dd')]
  if (relativePeriod == 'LASTMONTH') return [format(subMonths(now, 1), 'yyyy-MM') + '-01', format(lastDayOfMonth(subMonths(now, 1)), 'yyyy-MM-dd')]
  if (relativePeriod == 'LASTWEEK')
    return [format(startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }), 'yyyy-MM-dd'), format(endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }), 'yyyy-MM-dd')]
  if (relativePeriod == 'LAST7') return [format(subDays(now, 6), 'yyyy-MM-dd'), format(now, 'yyyy-MM-dd')]
  if (relativePeriod == 'THISWEEK') return [format(startOfWeek(now, { weekStartsOn: 1 }), 'yyyy-MM-dd'), format(now, 'yyyy-MM-dd')]
  if (relativePeriod == 'THISMONTH') return [format(now, 'yyyy-MM') + '-01', format(now, 'yyyy-MM-dd')]
}

function calculateMonth(monthNumber, year) {
  const now = new Date()
  const monthDate = new Date(parseInt(year), monthNumber, 1)
  const isCurrentOrFutureMonth = year == now.getFullYear() && monthNumber >= now.getMonth()

  if (isCurrentOrFutureMonth) return [format(now, 'yyyy-MM') + '-01', format(now, 'yyyy-MM-dd')]
  return [format(monthDate, 'yyyy-MM') + '-01', format(lastDayOfMonth(monthDate), 'yyyy-MM-dd')]
}

function calculateQuarter(quarter, year) {
  if (quarter == 'Q1') return [year + '-01-01', year + '-03-31']
  if (quarter == 'Q2') return [year + '-04-01', year + '-06-30']
  if (quarter == 'Q3') return [year + '-07-01', year + '-09-30']
  if (quarter == 'Q4') return [year + '-10-01', year + '-12-31']
}

// query: { period: 'LAST365', monthName: '', monthNumber: '', year: '', startdate: '', enddate: '' }

function calculateDateRange(query): { dates: string[]; name: string } {
  // user has selected a relative period
  if (query.period) {
    return {
      dates: calculateRelativePeriod(query.period.toUpperCase()),
      name: query.period,
    }
  }

  // user has selected a month
  if (query.monthNumber >= 0) {
    return {
      dates: calculateMonth(query.monthNumber, query.year),
      name: `${query.monthName} ${query.year}`,
    }
  }

  // user has selected a year
  if (query.wholeYear) {
    return {
      dates: calculateYear(query.wholeYear),
      name: query.wholeYear,
    }
  }

  // user has selected a quarter
  if (query.quarter) {
    return {
      dates: calculateQuarter(query.quarter, query.year),
      name: `${query.quarter} ${query.year}`,
    }
  }

  // user has selected a date range
  return { dates: [query.startdate, query.enddate], name: 'custom' }
}

export { calculateDateRange }
