<template>
  <v-breadcrumbs :items="breadcrumbs" large class="pl-0 ellipsis">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item exact :disabled="$store.state.editing" :class="item.disabled ? 'black--text' : 'primary--text'" @click="click(item)">
        <span class="text-md-h6 text-lg-h5 text-xl-h4" style="cursor:pointer;">{{ item.text }}</span>
      </v-breadcrumbs-item>
    </template>
    <template v-slot:divider>
      <v-icon>mdi-chevron-double-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>
<script>
export default {
  props: ['breadcrumbs'],
  data() {
    return {
      swBreadcrumbs: [],
    }
  },
  watch: {
    $props: {
      handler() {
        this.swBreadcrumbs = []
        this.breadcrumbs.forEach((bc) => {
          if (!bc.text) return
          if (bc.to) bc.type = 'button'
          this.swBreadcrumbs.push(bc)
        })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async click(item) {
      if (item.to) {
        this.$router.push({ path: item.to }).catch((err) => err)
      }
      if (item.callback) {
        this.$emit('callback')
        if (typeof item.callback === 'function') item.callback()
      }
    },
  },
}
</script>
