<template>
  <v-container class="pa-0">
    <div v-for="(data, mainKey, idx) in onScreenData" :key="mainKey">
      <v-card v-if="data" :class="idx ? 'mt-2' : ''">
        <v-card-text class="pa-4">
          <v-row v-if="data.rowMeta['sku.brand'] && data.rowMeta['sku.articleCodeSupplier']">
            <v-col cols="12" sm="3" lg="2" class="pr-0">
              <v-card>
                <v-expand-transition appear>
                  <v-img
                    :src="image(data.rowMeta['sku.brand'], data.rowMeta['sku.articleCodeSupplier'], 600)"
                    :lazy-src="image(data.rowMeta['sku.brand'], data.rowMeta['sku.articleCodeSupplier'], 75)"
                    aspect-ratio="1"
                    class="grey lighten-4 elevation-3"
                    contain
                    data-test="productImage"
                  />
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12" sm="9" lg="10">
              <v-card-title class="pa-0 d-flex flex-column align-start">
                <div class="d-flex flex-row align-start">
                  <div v-for="(grouper, id) in Object.entries(data.rowMeta).slice(0, -1)" :key="id" class="px-2 pb-2 mr-2 rounded-lg">
                    <span class="caption">{{ swT(grouper[0]) }}</span>
                    <h4 class="font-weight-medium mt-n2">{{ grouper[1] }}</h4>
                  </div>
                </div>
                <div v-if="selectedKPIs && selectedKPIs.length && data.tableKPI" class="mt-1">
                  <v-chip v-for="kpi in selectedKPIs" :key="kpi.value" class="my-1 mr-2 white">
                    {{ swT(kpi.text) }}: {{ stockInsight.formatKpi(kpi.fieldType, data.tableKPI[kpi.value]) }}
                  </v-chip>
                </div>
              </v-card-title>
              <div>
                <v-card class="mt-1">
                  <v-card-text class="pa-0 elevation-3">
                    <!-- TODO: Should only be used when the table key is sku.size -->
                    <swStockInsightSizeTable v-if="$store.state.articleStatus.selectedFields.length" :kpis="data.tableData" />
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>

          <!-- Without brand we can't show images -->
          <v-row v-else>
            <v-col cols="12">
              <v-card-title class="pa-0 d-flex flex-column align-start">
                <div class="d-flex flex-row align-start">
                  <div v-for="(grouper, id) in Object.entries(data.rowMeta).slice(0, -1)" :key="id" class="px-2 pb-2 mr-2 rounded-lg">
                    <span class="caption">{{ swT(grouper[0]) }}</span>
                    <h4 class="font-weight-medium mt-n2">{{ grouper[1] }}</h4>
                  </div>
                </div>
                <div v-if="selectedKPIs && selectedKPIs.length && data.tableKPI" class="mt-1">
                  <v-chip v-for="kpi in selectedKPIs" :key="kpi.value" class="my-1 mr-2 white">
                    {{ swT(kpi.text) }}: {{ stockInsight.formatKpi(kpi.fieldType, data.tableKPI[kpi.value]) }}
                  </v-chip>
                </div>
              </v-card-title>
              <div>
                <v-card class="mt-1">
                  <v-card-text class="pa-0 elevation-3">
                    <!-- TODO: Should only be used when the table key is sku.size -->
                    <swStockInsightSizeTable v-if="$store.state.articleStatus.selectedFields.length" :kpis="data.tableData" />
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import swStockInsightSizeTable from './swStockInsightSizeTable.vue'
import stockInsight from '../functions/stockInsight'
import { eventBus } from '../main'
import { swT } from '@/functions/i18n'
import globalStore from '../store/globalStore'
import tools from '../functions/tools'

export default {
  name: 'StockInsightReport',
  components: {
    swStockInsightSizeTable,
  },
  props: ['selectedFields', 'dates', 'selectedGroups', 'itemsPerPage'],
  data() {
    return {
      fields: {},
      swT,
      stockInsight, // necessary if methods are used in the template
      localInsightData: {}, // local stock insight data
      localKeyIndex: [], // sorted keys of local stock insight data
      onScreenData: {}, // paginated stock insight data
      localInsight: {}, // cached insight data
    }
  },
  computed: {
    selectedKPIs() {
      return this.$store.state.articleStatus.selectedKPIs
    },
  },
  watch: {
    itemsPerPage() {
      this.onScreenData = stockInsight.buildUIData(this.localKeyIndex, this.localInsightData, this.$store.state.paginationCurrentPage, this.itemsPerPage)
      this.dataLengthHasChanged(this.localKeyIndex)
    },
    '$store.state.paginationCurrentPage': {
      handler() {
        this.onScreenData = stockInsight.buildUIData(this.localKeyIndex, this.localInsightData, this.$store.state.paginationCurrentPage, this.itemsPerPage)
      },
    },
    '$store.state.filter': {
      handler() {
        const lowerCaseFilter = this.$store.state.filter?.toLowerCase() || ''

        if (lowerCaseFilter == '') {
          this.onScreenData = stockInsight.buildUIData(this.localKeyIndex, this.localInsightData, this.$store.state.paginationCurrentPage, this.itemsPerPage)
          this.dataLengthHasChanged(this.localKeyIndex)
        } else {
          const filteredKeys = stockInsight.appLevelFilterItems(this.localKeyIndex, this.$store.state.filter)
          this.onScreenData = stockInsight.buildUIData(filteredKeys, this.localInsightData, this.$store.state.paginationCurrentPage, this.itemsPerPage)
          this.dataLengthHasChanged(filteredKeys)
        }
      },
    },
  },
  created() {
    this.$store.state.articleStatus.itemsPerPage = 5
    // Here we get the insight data from the stockinsight function
    // These are processes that happen only when agg data is updated
    eventBus.$on('updateVisualizations', (agg) => {
      const selectedGroups = this.$store.state.articleStatus.selectedGroups
      const { insight, keyIndex } = stockInsight.buildDataStructure(agg, this.dates, selectedGroups)

      this.localKeyIndex = keyIndex
      this.localInsightData = insight
      this.dataLengthHasChanged(keyIndex)

      this.onScreenData = stockInsight.buildUIData(this.localKeyIndex, this.localInsightData, this.$store.state.paginationCurrentPage, this.itemsPerPage)
    })
  },
  destroyed() {
    this.$store.state.articleStatus.itemsPerPage = 20
    this.$store.state.paginationTotalPages = -1
    this.$store.state.articleStatus.selectedGroups = []
    eventBus.$off('updateVisualizations')
  },
  methods: {
    dataLengthHasChanged(keyIndex) {
      eventBus.$emit('UIDataLengthHasChanged', keyIndex.length)
      this.$store.state.articleStatus.totalItems = keyIndex.length
      this.$store.state.paginationTotalPages = Math.ceil(keyIndex.length / this.itemsPerPage)
    },
    image(brand, articleCodeSupplier, width) {
      const productKey = `${brand}__${articleCodeSupplier}`
      const index = globalStore.getIndexedProducts()
      const images = index[productKey]?.[0]?.images
      if (!images) return ''
      return this.imageSrc(images.split(',')[0], width)
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
  },
}
</script>
