<template>
  <v-row>
    <v-col class="d-flex align-center justify-start">
      <swDateRangePicker
        :data-test="`${dates}`"
        :start-date="dates[0]"
        :end-date="dates[1]"
        :min-date="`${$store.getters.years[0]}-01-01`"
        @updateDateRange="updateDates($event)"
      />

      <v-text-field
        outlined
        hide-details
        dense
        :value="$store.state.articleStatus.itemsPerPage"
        :label="swT('rowsperpage')"
        type="number"
        min="1"
        class="ml-4"
        style="max-width:80px;"
        max="1000"
        data-cy="rowsPerPage"
        :data-test="`rowsPerPage-${$store.state.articleStatus.itemsPerPage}`"
        @input="$store.state.articleStatus.itemsPerPage = Math.max(1, parseInt($event, 10) || 1)"
      ></v-text-field>

      <v-tooltip v-if="totalItems" top>
        <template v-slot:activator="{ on }">
          <div hide-details dense disabled :label="swT('total_items')" class="ml-4 primary--text" style="max-width:80px;" v-bind="$attrs" v-on="on">
            {{ totalItems }}
          </div>
        </template>
        <span>{{ swT('total_items') }}</span>
      </v-tooltip>
    </v-col>

    <v-col v-if="$store.state.reportViz === 'table'" class="d-flex align-center justify-center">
      <v-btn
        v-if="showSubtotalsButton"
        small
        class="mx-1"
        hide-details
        :label="swT('withSubtotals')"
        data-cy="subtotals"
        :data-test="`subtotals${$store.state.articleStatus.withSubtotals}`"
        @click.stop="$store.state.articleStatus.showSubtotals = !$store.state.articleStatus.showSubtotals"
      >
        {{ subtotalsButtonText }}
      </v-btn>
      <v-btn
        small
        class="mx-1"
        hide-details
        :label="swT('articleStatusTableViz')"
        :data-cy="`switchArticleStatusViz-${$store.state.articleStatusTableViz}`"
        data-test="switchArticleStatusViz"
        @click="switchArticleStatusViz()"
      >
        {{ swT($store.state.articleStatusTableViz === 'chart' ? 'table' : 'chart') }}
      </v-btn>
    </v-col>

    <v-col class="d-flex align-center justify-end pr-3">
      <v-tooltip v-if="localConsistencyOK" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="info" large>mdi-check-decagram</v-icon>
          </v-btn>
        </template>
        <span>{{ swT('consistency_checked') }} {{ localConsistencyChecked }}</span>
      </v-tooltip>

      <v-tooltip v-if="localConsistencyWaiting" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="warning" large>mdi-alert-decagram</v-icon>
          </v-btn>
        </template>
        <span>{{ swT('waiting_for_transaction_data_to_process') }}</span>
      </v-tooltip>

      <v-btn small hide-details color="info" class="mx-1" @click.stop="$store.state.foldReportsUI = !$store.state.foldReportsUI">
        <v-icon v-if="!$store.state.foldReportsUI">mdi-fullscreen</v-icon>
        <v-icon v-else>mdi-fullscreen-exit</v-icon>
      </v-btn>

      <div v-if="$store.state.reportViz !== 'table'">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small block color="success" data-test="printButton" v-bind="attrs" v-on="on" @click="print">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          <span>{{ swT('print') }}</span>
        </v-tooltip>
      </div>

      <v-menu v-if="$store.state.reportViz === 'table'" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="no-print" color="success" data-test="actionMenu" small v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <!-- List of menu actions -->
        <div class="pt-1 pb-1 px-1 no-print" color="white" style="background-color:white">
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 pr-1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small block color="success" data-test="printButton" v-bind="attrs" v-on="on" @click="print">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span class="no-print">{{ swT('print') }}</span>
              </v-tooltip>
            </v-col>
            <v-col class="pa-0 pl-1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="$store.state.reportViz == 'table'"
                    small
                    block
                    color="success"
                    :loading="$store.state.exportBusy"
                    :data-test="`copyToExcelButton`"
                    v-bind="attrs"
                    v-on="on"
                    @click="copyToExcel"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>{{ swT('export_report_to_excel') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>

        <!-- Commands -->
        <div v-if="$store.state.reportViz === 'table'" class="no-print">
          <div v-for="command in commands" :key="command.command" class="pb-1 px-1" style="background-color:white">
            <v-btn small block :color="command.color" :data-test="`command-${command.command}`" @click="callRunCommand(command)">
              <v-icon v-if="command.icon">
                {{ command.icon }}
              </v-icon>
              {{ swT(command.command) }}
            </v-btn>
          </div>
        </div>

        <!-- Wizard / Link -->
        <div class="pb-1 px-1 no-print" color="white" style="background-color:white">
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 pr-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$store.state.reportViz === 'table'"
                    small
                    block
                    hide-details
                    :label="swT('fullUI')"
                    color="info"
                    data-test="goToWizard"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="
                      $store.state.reportsFilterFullUI = !$store.state.reportsFilterFullUI
                      localFilterStepperModel = 0
                    "
                  >
                    <v-icon>mdi-wizard-hat</v-icon>
                  </v-btn>
                </template>
                <span>{{ swT('go_to_wizard') }}</span>
              </v-tooltip>
            </v-col>
            <v-col class="pa-0 pl-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-show="$store.state.reportViz == 'table'" small block color="info" :data-test="`copyDeepLink`" v-bind="attrs" v-on="on" @click="copyDeeplinkHandler()">
                    <v-icon>mdi-link-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{ swT('copy_report_link') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-menu>

      <div v-if="$store.state.reportViz == 'stockinsight'" class="ml-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small block color="info" :data-test="`copyDeepLink`" v-bind="attrs" v-on="on" @click="copyDeeplinkHandler()">
              <v-icon>mdi-link-variant</v-icon>
            </v-btn>
          </template>
          <span>{{ swT('copy_report_link') }}</span>
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { swT } from '@/functions/i18n'
import swDateRangePicker from '@/components/swDateRangePicker'
import { eventBus } from '../main'

export default {
  components: { swDateRangePicker },
  props: ['dates', 'consistencyChecked', 'commands'],
  data() {
    return {
      swT,
      localConsistencyChecked: null,
      ignoreFirstUpdate: true,
      totalItems: 0,
    }
  },
  computed: {
    showCommands() {
      return this.$store.state.reportsFilterFullUI && this.$store.state.foldReportsUI && this.$vuetify.breakpoint.mdAndUp && this.$route.query.type != 'stock'
    },
    subtotalsButtonText() {
      return this.$store.state.articleStatus.showSubtotals ? this.swT('withoutSubtotals') : this.swT('withSubtotals')
    },
    showSubtotalsButton() {
      return this.$store.state.articleStatus.selectedGroups.length > 1 && this.$store.state.articleStatusTableViz === 'table'
    },
    localConsistencyWaiting() {
      return !this.$store.state.user.tenant?.salesMonitor && this.localConsistencyChecked == null
    },
    localConsistencyOK() {
      return !this.$store.state.user.tenant?.salesMonitor && this.localConsistencyChecked
    },
    localTotalItems() {
      return this.$store.state.articleStatus.totalItems
    },
  },
  watch: {
    $props: {
      handler() {
        this.updateValuesFromProps()
      },
      deep: true,
    },
  },
  created() {
    eventBus.$on('UIDataLengthHasChanged', (length) => {
      this.totalItems = length
    })
  },
  methods: {
    callRunCommand(command) {
      this.$emit('run-command', command)
    },
    switchArticleStatusViz() {
      this.$store.state.articleStatusTableViz === 'table' ? (this.$store.state.articleStatusTableViz = 'chart') : (this.$store.state.articleStatusTableViz = 'table')
    },
    updateValuesFromProps() {
      this.localConsistencyChecked = this.consistencyChecked
    },
    copyToExcel() {
      eventBus.$emit('copyToExcel')
    },
    updateDates(dates) {
      // the first update is triggered by the dateRangePicker component and overrides user selected dates in the wizard
      if (this.ignoreFirstUpdate) {
        this.ignoreFirstUpdate = false
        return
      }
      this.$emit('updateDates', { startdate: dates[0], enddate: dates[1] })
    },
    copyDeeplinkHandler() {
      this.$emit('copyDeeplink')
    },
    print() {
      this.$store.state.articleStatus.itemsPerPage = 1000
      this.$nextTick(() => {
        window.print()
      })
    },
  },
}
</script>
